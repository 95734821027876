@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: "Yekan" !important;
}

.switcher {
  display: block;
  height: 230px;
  width: 280px;
  background: #fff;
  padding: 30px;
  position: fixed;
  top: 250px;
  right: -280px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.07);
  z-index: 99;
  transition: all 0.3s linear;
}

.switcher__toggle {
  box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.07);
  height: 40px;
  width: 40px;
  line-height: 40px;
  color: #333;
  background: #fff;
  position: absolute;
  left: -40px;
  text-align: center;
  cursor: pointer;
}

.switcher.js-switcher-open {
  right: -1px;
}

.color {
  position: relative;
  border-radius: 50%;
  width: 30px;
  display: inline-block;
  height: 30px;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 10px;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

.switcher__h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  color: #333;
}

.switcher__text {
  display: block;
  font-size: 13px;
  color: #7f7f7f;
}

.switcher__color {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
}

.color.js-color-select:after {
  color: #fff;
  content: "\F00C";
  font-family: Font Awesome\5 Free;
  font-size: 8px;
  font-weight: 900;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  z-index: 2;
}

 .mbsc-ios.mbsc-textfield-wrapper-outline {
  margin: 10px 0 !important;
 }

 

@font-face {
  font-family: "Yekan";
  src: url(../public/fonts/Shabnam-FD.ttf);
}

@font-face {
  font-family: "Shabnam-thin";
  src: url(../public/fonts/Shabnam-Thin-FD.ttf);
}

.bg_0 {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #00000023;
}

